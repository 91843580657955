import {http} from "@/services/config";

export default {
    index: (filter, page) => {
        return http.get('api/v1/planos?page=' + page, {params: filter});
    },
    listAll: () => {
        return http.get('api/v1/planos/listar');
    },
    create: (data) => {
        return http.post('api/v1/planos', data);
    },
    edit: (id, data) => {
        return http.put(`api/v1/planos/${id}`, data);
    },
    show: (id) => {
        return http.get(`api/v1/planos/${id}`);
    },
}